import {
  type CreateVeranstaltungInput,
  type CreateVeranstaltungOutput,
  geschaeftsbereiche,
  type ResourcenBlockungen,
  terminart,
  type VeranstaltungCustomProperties,
} from '../../../dtos';
import { trpc } from '../../../trpc';
import { getItemFromSessionStorage } from '../../../utils/sessionStorage';
import { ContentLoading } from '../../general/ContentLoading';
import { CalendarResourceOverview } from './planung/CalendarResourceOverview';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Paper } from '@mui/material';
import { set } from 'date-fns';
import { closeSnackbar, type SnackbarKey, useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type VeranstaltungsEditProps = {
  readonly themaId: number;
  readonly themaSapId: number;
  readonly veranstaltungId: number | null;
};

const convertTimeStringToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });
};

export const VeranstaltungsEdit: React.FC<VeranstaltungsEditProps> = (props: VeranstaltungsEditProps) => {
  const { themaId, themaSapId, veranstaltungId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const navigate = useNavigate();

  let ablaufTageTemplate = null;
  let ablauf = null;
  let status;
  if (veranstaltungId === null) {
    // this is what happens in case of creating a new Veranstaltung
    const query = trpc.thema.getThema.useQuery({ themaId });
    status = query.status;
    ablaufTageTemplate = query.data?.thema.planungsinformation?.ablaufTageTemplate;
  } else {
    // this is what happens if the veranstaltung already exists
    const query = trpc.veranstaltung.getVeranstaltungById.useQuery({ id: veranstaltungId });
    status = query.status;
    ablauf = query.data?.ablauf ?? null;
  }

  const trpcUtils = trpc.useUtils();
  const createVeranstaltungMutation = trpc.veranstaltung.createVeranstaltung.useMutation({
    onSuccess: (createVeranstaltungOutput: CreateVeranstaltungOutput) => {
      enqueueSnackbar(`Veranstaltung mit ID ${createVeranstaltungOutput.veranstaltungId} gespeichert!`, { variant: 'success' });
    },
    onError: () => {
      const action = (snackbarId: SnackbarKey): React.ReactNode => (
        <IconButton
          aria-label="close"
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          <CloseIcon />
        </IconButton>
      );

      const fehlerId = getItemFromSessionStorage('latest-X-Viva-Request-Id');

      const nodeWithoutFehlerId = (
        <div>
          <p>Es trat ein Fehler beim Speichern der Veranstaltung auf.</p>
        </div>
      );

      const nodeWithFehlerId = (
        <div>
          <p>Es trat ein Fehler beim Speichern der Veranstaltung auf. Wende dich bitte mit Fehler-ID</p>
          <p>{fehlerId}</p>
          <p>an das VAMOS Team.</p>
        </div>
      );

      enqueueSnackbar(fehlerId === null ? nodeWithoutFehlerId : nodeWithFehlerId, {
        variant: 'error',
        persist: true,
        action,
      });
    },
  });

  if (status === 'pending') {
    return <ContentLoading />;
  }

  const toggleFullscreen = (): void => {
    setIsFullscreen((prev) => !prev);
  };

  const onSave = async (blockungen: ResourcenBlockungen, properties?: VeranstaltungCustomProperties): Promise<void> => {
    const input: CreateVeranstaltungInput = {
      themaId,
      blockungen,
      terminart: properties?.terminart ?? terminart.STANDARDTERMIN,
      manuelleTerminanfrage: properties?.manuelleTerminanfrage ?? false,
    };

    await createVeranstaltungMutation.mutateAsync(input);
    await trpcUtils.invalidate();
  };

  const onCancel = (): void => {
    navigate(`/thema/${themaSapId}`);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
      <CalendarResourceOverview
        onSave={onSave}
        onCancel={onCancel}
        themaId={themaId}
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
        initDate={new Date()}
        terminInfo={{
          themaId,
          division: geschaeftsbereiche.KFF,
          // todo wenn ablaufTagTemplate nicht vorhanden dann wollen wir dem User einen Fehler anzeigen: keine Planungsinformation vorhanden
          ablauf: ablauf ?? [
            ...(ablaufTageTemplate?.map((ablaufTagTemplate) => ({
              startDateTime: convertTimeStringToDate(ablaufTagTemplate.startZeit),
              endeDateTime: convertTimeStringToDate(ablaufTagTemplate.endZeit),
            })) ?? []),
          ],
        }}
      />
    </Paper>
  );
};
