import { type Appointment, type GetAppointmentsOutput } from '../../../dtos';
import { type TypedGridColdDef } from '../../../utils/gridHelper';
import { copyToClipboard, defaultDateOptions, generateClipboardTextForThema } from './AppointmentCheckThemaRow.utils';
import { CopyAllSharp } from '@mui/icons-material';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

const appointmentsCheckColDefs: Array<TypedGridColdDef<Appointment>> = [
  {
    field: 'startDate',
    headerName: 'Startdatum',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (value: Date) => value.toLocaleDateString('de-DE', defaultDateOptions),
  },
  {
    field: 'endDate',
    headerName: 'Enddatum',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (value: Date) => value.toLocaleDateString('de-DE', defaultDateOptions),
  },
  {
    field: 'akaVeranstaltungId',
    headerName: 'AKA-VeranstaltungId',
    width: 150,
    editable: false,
    type: 'string',
  },
  {
    field: 'location',
    headerName: 'Ort',
    width: 350,
    editable: false,
    type: 'string',
  },
  {
    field: 'ortsKuerzel',
    headerName: 'Ortskürzel',
    width: 150,
    editable: false,
    type: 'string',
  },
  {
    field: 'standortName',
    headerName: 'Standort',
    width: 150,
    editable: false,
    type: 'string',
  },
  {
    field: 'experten',
    headerName: 'Trainer',
    width: 300,
    editable: false,
    type: 'string',
    renderCell: (params) => params.row.experten.map((tra) => tra.name).join(', '),
  },
];

type ThemaRowCardProps = {
  readonly data: GetAppointmentsOutput;
  readonly themaTitle?: string;
  readonly buchungsnummer?: string;
};

export const ThemaRowCard: React.FC<ThemaRowCardProps> = ({ data, themaTitle, buchungsnummer }: ThemaRowCardProps) => {
  const copySingleElementToClipboard = async (): Promise<void> => {
    await copyToClipboard(generateClipboardTextForThema(data, themaTitle, buchungsnummer));
  };

  return (
    <Paper>
      <Box sx={{ padding: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {themaTitle} ({buchungsnummer})
          </Typography>
          <Button onClick={async () => await copySingleElementToClipboard()}>
            <CopyAllSharp /> Kopieren
          </Button>
        </Stack>
        <DataGridPro sx={{ marginTop: 2, height: 400, width: '100%' }} columns={appointmentsCheckColDefs} rows={data.map((item, idx) => ({ id: idx, ...item }))} />
      </Box>
    </Paper>
  );
};
