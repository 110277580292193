import { type AppRouter } from '@hg-akademie-ban/vamos-viva-main';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import { z } from 'zod';

// Some of the hard-coded stuff in here is by no means "ideal" - however, it is a step up from randomly importing things from the backend.
// So, if you have better ideas, feel free ;)

/*
 * Helpers
 */

type RouterInput = inferRouterInputs<AppRouter>;
type RouterOutput = inferRouterOutputs<AppRouter>;

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends ReadonlyArray<infer ElementType> ? ElementType : never;

type NonNullable<T> = Exclude<T, null | undefined>;

type Replace<
  SourceString extends string,
  SearchString extends string,
  ReplaceString extends string,
  AccumulatedResult extends string = '',
> = SourceString extends `${infer Left}${SearchString}${infer Right}`
  ? Replace<Right, SearchString, ReplaceString, `${AccumulatedResult}${Left}${ReplaceString}`>
  : `${AccumulatedResult}${SourceString}`;

export type Keyify<TKey extends string> = Uppercase<Replace<TKey, ' ' | '-', '_'>>;

/*
 * Router
 */

// search

export type SearchThemaInput = RouterInput['search']['thema'];
export type SearchThemaOutput = RouterOutput['search']['thema'];

export type ThemaFromSearchRouter = ArrayElement<SearchThemaOutput>;

// thema

export type GetThemaInput = RouterInput['thema']['getThema'];
export type GetThemaOutput = RouterOutput['thema']['getThema'];

export type ThemaFromThemaRouter = GetThemaOutput['thema'];
export type PlanungsinformationFromThemaRouter = NonNullable<ThemaFromThemaRouter['planungsinformation']>;
export type AblaufTagTemplate = PlanungsinformationFromThemaRouter['ablaufTageTemplate'];
export type Experteplanung = ArrayElement<NonNullable<PlanungsinformationFromThemaRouter['expertePool']>>;
export type Clusterzuordnung = Experteplanung['clusterzuordnung'];

export const clusterzuordnung = {
  HAUPTTRAINER: 'haupttrainer', // cluster A
  NEBENTRAINER: 'nebentrainer', // cluster B
  ERSATZTRAINER: 'ersatztrainer', // cluster C
} as const satisfies Record<Keyify<Clusterzuordnung>, Clusterzuordnung>;

export type ExperteFromThemaRouter = NonNullable<Experteplanung['experte']>;
export type CservFormat = ThemaFromThemaRouter['strukturellesFormat'];

export const cservFormat = {
  STANDARD_SEMINAR: 551,
  ONLINE_KURS: 569,
} as const satisfies Record<string, CservFormat>;

// veranstaltung

export type GetVeranstaltungByIdInput = RouterInput['veranstaltung']['getVeranstaltungById'];
export type GetVeranstaltungByIdOutput = RouterOutput['veranstaltung']['getVeranstaltungById'];

export type Veranstaltung = GetVeranstaltungByIdOutput;

export type AkaVeranstaltungId = GetVeranstaltungByIdOutput['akaVeranstaltungId'];

export type ExperteBlockung = ArrayElement<Veranstaltung['experteBlockungen']>;
export type Zeitraeume = ExperteBlockung['zeitraeume'];
export type Zeitraum = ArrayElement<Zeitraeume>;

export type HiveTerminanfrageStatus = NonNullable<ExperteBlockung['hiveTerminanfrageStatus']>;

export const hiveTerminanfrageStatus = {
  MANUAL: 'MANUAL',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CONFLICT: 'CONFLICT',
  CANCELLED: 'CANCELLED',
} as const satisfies Record<Keyify<HiveTerminanfrageStatus>, HiveTerminanfrageStatus>;

export type ExperteHiveTerminanfrageConflict = ArrayElement<NonNullable<ExperteBlockung['hiveTerminanfrageConflicts']>>;

export type Geschaeftsbereich = GetVeranstaltungByIdOutput['geschaeftsbereich'];

export const geschaeftsbereiche = {
  CAS: 'CAS',
  DLS: 'DLS',
  ILS: 'ILS',
  IHC: 'IHC',
  KFF: 'KFF',
  UNKNOWN: 'UNKNOWN',
} as const satisfies Record<Keyify<Geschaeftsbereich>, Geschaeftsbereich>;

export type VivaStatus = NonNullable<GetVeranstaltungByIdOutput['vivaStatus']>;

export const vivaStatus = {
  INPLANUNG: 'inPlanung',
  ABGESCHLOSSEN: 'abgeschlossen',
  FREIGEGEBEN: 'freigegeben',
} as const satisfies Record<Keyify<VivaStatus>, VivaStatus>;

export type SapStatus = NonNullable<GetVeranstaltungByIdOutput['statusSap']>;

export const sapStatus = {
  ABGESAGT: 'abgesagt',
  FIXIERT: 'fixiert',
  GEPLANT: 'geplant',
  GESPERRT: 'gesperrt',
} as const satisfies Record<Keyify<SapStatus>, SapStatus>;

export type AblaufTag = ArrayElement<GetVeranstaltungByIdOutput['ablauf']>;

export type Terminart = GetVeranstaltungByIdOutput['terminart'];

export const terminart = {
  STANDARDTERMIN: 'standardtermin',
  ZUSATZTERMIN: 'zusatztermin',
  ERSATZTERMIN: 'ersatztermin',
} as const satisfies Record<Keyify<Terminart>, Terminart>;

export type FindByThemaIdInput = RouterInput['veranstaltung']['findByThemaId'];
export type FindByThemaIdOutput = RouterOutput['veranstaltung']['findByThemaId'];

export type FindHoverInfoByIdInput = RouterInput['veranstaltung']['findHoverInfoById'];
export type FindHoverInfoByIdOutput = RouterOutput['veranstaltung']['findHoverInfoById'];

export type FindUrlInfoByIdInput = RouterInput['veranstaltung']['findUrlInfoById'];
export type FindUrlInfoByIdOutput = RouterOutput['veranstaltung']['findUrlInfoById'];

export type CreateVeranstaltungInput = RouterInput['veranstaltung']['createVeranstaltung'];
export type CreateVeranstaltungOutput = RouterOutput['veranstaltung']['createVeranstaltung'];

export type MutateGeschaeftsbereichInput = RouterInput['veranstaltung']['mutateGeschaeftsbereich'];
export type MutateGeschaeftsbereichOutput = RouterOutput['veranstaltung']['mutateGeschaeftsbereich'];

export type MutateAblaufInput = RouterInput['veranstaltung']['mutateAblauf'];
export type MutateAblaufOutput = RouterOutput['veranstaltung']['mutateAblauf'];

export type UpdateResourcenBlockungenOfVeranstaltungInput = RouterInput['veranstaltung']['updateResourcenBlockungenOfVeranstaltung'];
export type UpdateResourcenBlockungenOfVeranstaltungOutput = RouterOutput['veranstaltung']['updateResourcenBlockungenOfVeranstaltung'];

export type ResourcenBlockungen = UpdateResourcenBlockungenOfVeranstaltungInput['blockungen'];
export type ResourcenBlockung = ArrayElement<ResourcenBlockungen>;
export type ResourcenBlockungType = ResourcenBlockung['type'];

export const resourcenBlockungTypes = {
  EXPERTE: 'experte',
  RAUM: 'raum',
  SLOT: 'slot',
} as const satisfies Record<Keyify<ResourcenBlockungType>, ResourcenBlockungType>;

export type CheckHealthInput = RouterInput['veranstaltung']['checkHealth'];
export type CheckHealthOutput = RouterOutput['veranstaltung']['checkHealth'];

export type CheckHealthResult = ArrayElement<CheckHealthOutput>;

export type HealthLevel = CheckHealthResult['type'];

export const healthLevel = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
} as const satisfies Record<Keyify<HealthLevel>, HealthLevel>;

// planung

export type GetPlanungsinformationInput = RouterInput['planung']['getPlanungsinformation'];
export type GetPlanungsinformationOutput = RouterOutput['planung']['getPlanungsinformation'];

export type Expertepool = GetPlanungsinformationOutput['expertepool'];
export type ExperteFromPlanungRouter = ArrayElement<Expertepool>;
export type CservWorkflowStatus = NonNullable<GetPlanungsinformationOutput['cservWorkflowStatus']>;

export const cservWorkflowStatus = {
  Produktmanagement: 160,
  VAOrga: 161,
  PlanungAbgeschlossen: 162,
  PlanungInaktiv: 190,
} as const satisfies Record<string, CservWorkflowStatus>;

export type CservVcTools = NonNullable<GetPlanungsinformationOutput['vcTool']>;

export const cservVcTools = {
  MS_TEAMS: 'ms_teams',
  LUX: 'LUX',
  TECHCAST: 'techcast',
  TRICAT: 'tricat',
  ZOOM: 'zoom',
} as const satisfies Record<Keyify<CservVcTools>, CservVcTools>;

export type ChangeStatusForVeranstaltungenInput = RouterInput['planung']['changeStatusForVeranstaltungen'];
export type ChangeStatusForVeranstaltungenOutput = RouterOutput['planung']['changeStatusForVeranstaltungen'];

export type StatusChangeResult = ArrayElement<ChangeStatusForVeranstaltungenOutput>;

export type VeranstaltungCustomProperties = {
  readonly terminart: Terminart;
  readonly manuelleTerminanfrage: boolean;
};

// resources

export type GetAvailableStandorteByRegionInput = RouterInput['planung']['resources']['getAvailableStandorteByRegion'];
export type GetAvailableStandorteByRegionOutput = RouterOutput['planung']['resources']['getAvailableStandorteByRegion'];

export type GetOrteWithRaeumeInput = RouterInput['planung']['resources']['getOrteWithRaeume'];
export type GetOrteWithRaeumeOutput = RouterOutput['planung']['resources']['getOrteWithRaeume'];

export type OrtWithRaeume = ArrayElement<GetOrteWithRaeumeOutput>;
export type Raeume = OrtWithRaeume['raume'];
export type Raum = ArrayElement<Raeume>;

export type BoughtAvailabalities = Raum['boughtAvailabilities'];
export type BoughtAvailability = ArrayElement<BoughtAvailabalities>;

export type GetExpertenByThemaInput = RouterInput['planung']['resources']['getExpertenByThema'];
export type GetExpertenByThemaOutput = RouterOutput['planung']['resources']['getExpertenByThema'];

export type ExperteFromResourcesRouter = ArrayElement<GetExpertenByThemaOutput>;
export type ExperteBlockungQuelle = ArrayElement<ExperteFromResourcesRouter['blockings']>['quelle'];

export const experteBlockungQuelle = {
  SAP: 'AKA.BAN.SAP',
  VAMOS: 'AKA.BAN.VMOS',
  CESAR: 'AKA.BAN.CESAR.SALESFORCE',
} as const satisfies Record<string, ExperteBlockungQuelle>;

export type GetStandortSlotsInput = RouterInput['planung']['resources']['getStandortSlots'];
export type GetStandortSlotsOutput = RouterOutput['planung']['resources']['getStandortSlots'];

export type StandortSlot = ArrayElement<GetStandortSlotsOutput>;

export type FindExperteByIdOrLastnameInput = RouterInput['planung']['resources']['getExperteByNachnameOrSapId'];
export type FindExperteByIdOrLastnameOutput = RouterOutput['planung']['resources']['getExperteByNachnameOrSapId'];

export type SelectedExperte = ArrayElement<FindExperteByIdOrLastnameOutput>;

// fehler
export type FachlicherFehlerResult = RouterOutput['fehler']['getFachlicheFehler'];
export type FachlicherFehler = ArrayElement<FachlicherFehlerResult>;

// reporting

export type GetAppointmentsInput = RouterInput['reporting']['getAppointments'];
export type GetAppointmentsOutput = RouterOutput['reporting']['getAppointments'];

export type AppointmentStatus = GetAppointmentsInput['status'];

export type Appointment = ArrayElement<GetAppointmentsOutput>;
export type AppointmentQueryMode = GetAppointmentsInput['mode'];

export type ExpertenFromReportingRouter = Appointment['experten'];
export type ExperteFromReportingRouter = ArrayElement<ExpertenFromReportingRouter>;

export const appointmentQueryMode = {
  BY_EXPERTE: 'BY_EXPERTE',
  BY_THEMA: 'BY_THEMA',
  BY_THEMA_AND_EXPERTE: 'BY_THEMA_AND_EXPERTE',
} as const satisfies Record<AppointmentQueryMode, AppointmentQueryMode>;

/*
 * Scopes
 */

export const vivaUserScopes = {
  PLANUNG_EDIT: 'planung:edit',
  THEMA_EDIT: 'thema:edit',
  SYSTEM_ADMIN: 'system:admin',
} as const;

const vivaUserScopesSchema = z.nativeEnum(vivaUserScopes);
export const vivaUserScopesArraySchema = z.array(vivaUserScopesSchema);
export type VivaUserScope = z.infer<typeof vivaUserScopesSchema>;

export { type AppRouter } from '@hg-akademie-ban/vamos-viva-main';
